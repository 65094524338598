import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import MetaSite from "./MetaSite";
import MetaSocial from "./MetaSocial";
import SchemaWebsite from "./SchemaWebsite";
import SchemaOrganization from "./SchemaOrganization";
import SchemaNewsArticle from "./SchemaNewsArticle";
import SchemaItemsList from "./SchemaItemsList";
import SchemaContactPage from "./SchemaContactPage";
import SchemaProduct from "./SchemaProduct";

import {
  contactPoint,
  getOrganization,
  imageObject,
  defaultImage,
} from "./seoHelpers";

// SEO Data
// ----------------------------------------------------------------------------
// Pass the entire page object to automatically populate all fields.
//
// Use individual props (ie: "title", "description" etc) on each page to
// override this field from page object.
//
// Fallback values are the siteMetadata defined in config/site-config.js.
// ----------------------------------------------------------------------------

const Seo = ({
  page,
  itemsList,
  title,
  shortTitle,
  description,
  image,
  url,
  siteUrl,
  lang,
  logo,
  settings,
}) => {
  const config = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          shortTitle
          description
          logoUrl
          siteUrl
          siteLang

          defaultImage
          defaultImageWidth
          defaultImageHeight
          defaultImageAlt
        }
      }
    }
  `);

  const fallback = config.site.siteMetadata;

  // Site title
  const siteTitle = settings.site_title || fallback.title;

  // Short title
  const seoShortTitle =
    shortTitle || settings.site_short_title || fallback.shortTitle;

  // Main page title
  let seoTitle = seoShortTitle;
  if (title) {
    seoTitle = title;
  } else if (page) {
    const { meta_title: metaTitle, title: pageTitle } = page.data;
    if (metaTitle && metaTitle !== "") {
      seoTitle = metaTitle;
    } else if (pageTitle && pageTitle !== "") {
      seoTitle = pageTitle;
    }
  }

  const titleBrand = new RegExp(`${fallback.shortTitle}$`, "gm").test(seoTitle)
    ? ""
    : ` | ${fallback.shortTitle}`;

  seoTitle += titleBrand;

  // Page description
  let seoDescription = settings.site_description || fallback.description;
  if (description) {
    seoDescription = description;
  } else if (page) {
    const { description: pageDescription, meta_description: metaDescription } =
      page.data;
    if (metaDescription) {
      seoDescription = metaDescription;
    } else if (
      pageDescription &&
      pageDescription.text &&
      pageDescription.text !== ""
    ) {
      seoDescription = pageDescription.text;
    }
  }

  // Page URL
  let seoUrl = fallback.siteUrl;
  seoUrl = url || (page && page.url);
  seoUrl = new URL(seoUrl, fallback.siteUrl).href;

  // Site URL
  const seoSiteUrl = siteUrl || fallback.siteUrl;

  // Page language
  let seoLang = fallback.siteLang;
  if (lang) {
    seoLang = lang;
  } else if (page) {
    if (page.lang && page.lang !== "") {
      seoLang = page.lang;
    }
  }

  // Site Logo
  let seoLogo = imageObject(defaultImage);
  if (logo) {
    seoLogo = imageObject(logo);
  }

  // Page Image
  // --------------------------------------------------------------------------
  // Must be an object which includes the keys "url", "alt", "dimensions".
  // "dimensions" must include the keys "height" and "width".
  let seoImage = defaultImage;
  if (image && image.url && image.url !== "") {
    seoImage = image;
  } else if (page) {
    const { meta_image: metaImage, image: pageImage } = page.data;
    if (metaImage && metaImage.url && metaImage.url !== "") {
      seoImage = metaImage;
    } else if (pageImage && pageImage.url && pageImage.url !== "") {
      seoImage = pageImage;
    }
  }

  // Contact points
  const {
    phones,
    emails,
    address_street: addressStreet,
    address_locality: addressLocality,
    address_postal: addressPostal,
    phone,
    email,
  } = settings.data;

  return (
    <>
      <MetaSite
        title={seoTitle}
        description={seoDescription}
        image={seoImage}
        url={seoUrl}
        siteUrl={seoSiteUrl}
        lang={seoLang}
        altLangs={page && page.alternate_languages}
      />
      <MetaSocial
        title={seoTitle}
        shortTitle={seoShortTitle}
        description={seoDescription}
        image={seoImage}
        url={seoUrl}
      />
      <SchemaWebsite
        title={seoTitle}
        description={seoDescription}
        image={seoImage}
        url={seoUrl}
        siteUrl={seoSiteUrl}
      />
      <SchemaOrganization
        name={seoShortTitle}
        alternateName={siteTitle}
        url={fallback.siteUrl}
        logo={seoLogo}
        contactPoint={contactPoint(phones, emails)}
        street={addressStreet}
        locality={addressLocality}
        postal={addressPostal}
        phone={phone}
        email={email}
      />
      {page && page.type === "post" && (
        <SchemaNewsArticle
          headline={seoTitle}
          image={seoImage}
          datePublished={
            page.data.publication_date || page.first_publication_date
          }
          author={getOrganization({
            name: seoShortTitle,
            alternateName: siteTitle,
            url: fallback.siteUrl,
            logo: seoLogo,
            contactPoint: contactPoint(phones, emails),
            street: addressStreet,
            locality: addressLocality,
            postal: addressPostal,
            phone,
            email,
          })}
        />
      )}
      {page && page.type === "contac" && (
        <SchemaContactPage
          title={seoTitle}
          description={seoDescription}
          image={seoImage}
          url={seoUrl}
        />
      )}
      {page && page.type === "product" && (
        <SchemaProduct
          name={seoTitle}
          description={seoDescription}
          model={page.data.subtitle}
          image={seoImage}
          brand={page.data.brand}
          url={seoUrl}
          price={page.lang === "el-gr" ? "Κατόπιν αιτήσεως" : "Upon request"}
        />
      )}
      {itemsList && <SchemaItemsList items={itemsList} siteUrl={seoSiteUrl} />}
    </>
  );
};

export default Seo;
